<template>
  <div>
    <filter-bar name="categories" :pSearch="search"></filter-bar>

    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center">
      <div class="bg-white w-full rounded-lg shadow">
        <div
          class="h-20 py-3 flex items-center border-gray-200 p-6 overflow-hidden"
        >
          <div class="flex">
            <div class="text-2xl font-bold text-green-700   ">Categories</div>
          </div>
          <div class="w-full flex justify-end">
            <router-link
              class="flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              to="/categories/new"
            >
              <i class="material-icons">add</i> add category
            </router-link>
          </div>
        </div>
        <div class="overflow-x-auto">
          <base-table
            :refresh="refresh"
            :content="content"
            :headers="headers"
            name="categories"
            :idWharhouse="idWharhouse"
            :currentUser="currentUser"
          >
          </base-table>
        </div>

        <div class="p-1">
          <div class="flex flex-col items-center my-12">
            <div v-if="paginate.lastpage > 1" class="flex text-gray-700">
              <button
                v-if="paginate.currentpage > 1"
                @click="prev"
                :disabled="paginate.currentpage == 1"
                class="h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_left </i>
              </button>
              <div class="flex h-8 font-medium rounded-full bg-gray-200">
                <div
                  v-if="paginate.currentpage != paginate.lastpage"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600  text-white"
                >
                  {{ paginate.currentpage }}
                </div>
                <div
                  v-else
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  <span v-if="paginate.currentpage != 1"> 1 </span>
                </div>

                <div
                  v-if="paginate.currentpage >= 1 && paginate.lastpage != 1"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  ...
                </div>

                <button
                  :class="
                    paginate.currentpage == paginate.lastpage
                      ? 'bg-green-600  text-white'
                      : ' '
                  "
                  class="w-8 md:flex justify-center items-center hover:bg-green-600  hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  {{ paginate.lastpage }}
                </button>
              </div>
              <button
                v-if="paginate.currentpage < paginate.lastpage"
                :disabled="paginate.currentpage == paginate.lastpage"
                @click="next"
                class="h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_right </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      content: [],
      headers: ["name", "description"],
      categories: [],
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
    };
  },
  async mounted() {
    await this.getCategories({ limit: this.limit });
  },

  filters: {},
  methods: {
    async getCategories(filters) {
      const res = await this.$server.search("categories", filters);
      if (res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else this.content = [];
    },
    async refresh() {
      this.getCategories({ limit: this.limit });
    },
    async search(data) {
      const filter = {
        name: data,
      };
      const res = await this.$server.find("categories", filter);
      if (res.content) this.content = res.content;
      else this.content = [];
    },
    /*********************************************************************************/

    async next() {
      const filters = {
        page: ++this.paginate.currentpage,
        limit: this.limit,
      };
      const res = await this.$server.search("categories", filters);
      if (res.content.results) this.content = res.content.results;
      else this.content = [];
    },
    async prev() {
      const filters = {
        page: --this.paginate.currentpage,
        limit: this.limit,
      };
      await this.getCategories(filters);
    },
  },
};
</script>

<style>
</style>




